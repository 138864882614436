<template>
    <div class="main" :class="[!Dark ? '' : 'isDark']">
        <template v-if="index > -1 ">
            <Header @dark="searchDark" :isDark="Dark" :collapsed="collapsed" @hideMenu="hideMenu" />
            <div class="content pion-fixed">
                <Menu :dark="Dark" :collapsed="collapsed" />
                <div class="cont pion-abs" :class="[OS == 'X11' ? 'badPad' : '', collapsed ? 'hide' : '' ]">
                    <Title :Title="Title" :dark="Dark" class="pt-20 pl-20" />
                    <router-view :dark="Dark" :collapsed="collapsed" />
                </div>
                <div class="foot">
                    <div class="center">
                        <img src="@/assets/images/icons/foot_logo.png" alt="">
                        <span>|</span>
                        <p>北京华云慧医科技有限公司</p>
                        <span>|</span>
                        <p>(京)网药械信息备字(2022)第00589号</p>
                    </div>
                </div>
            </div>
            <div class="shadow pion-fixed"></div>
        </template>
        <template v-else>
            <router-view />
        </template>
    </div>
</template>

<script>
    import { Header, Menu } from './component/index';
    import { Title } from '_c/Public/Public';
    import { getItem, setItem, deviceType } from '_js/Cache';
    export default {
        components:{
            Header,
            Menu,
            Title
        },
        watch:{
            $route(to){
                this.Title = to.meta.title;
            }
        },
        data(){
            return{
                Title: this.$route.meta.title,
                index: this.$route.meta.pageIndex,
                Dark: getItem('dark') || false,
                OS:'',
                collapsed: getItem('menuType') || false,
            }
        },
        methods:{
            searchDark(){
                let is = getItem('dark');
                this.$emit('dark')
                if(!is || is == undefined || is == 'undefined'){
                    this.Dark = true;
                    setItem('dark',true);
                    this.$emit('')
                    location.reload();
                }else{
                    setItem('dark',false);
                    this.Dark = false;
                    location.reload();
                }
            },
            hideMenu(val){
                this.collapsed = val;
            }
        },
        mounted(){
            this.collapsed = (window.innerWidth >= 768 && window.innerWidth < 1366) || deviceType().iPad;
            setItem('menuType',this.collapsed)
            if(!deviceType().iPhone && !deviceType().android ){
                // 手动添加mate标签 
                const meta = document.createElement('meta');
                meta.content = 'width=device-width,initial-scale=1.0,user-scalable=1';
                meta.name = 'viewport';
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
            // let winW = window.innerWidth,
            //     UIw = 1920,
            //     zoomVal = UIw / winW,
            //     dom = document.getElementsByClassName('main')[0],
            //     w = winW * zoomVal;
            // window.innerWidth = 1024;
            // setTimeout( () =>{
            //     console.log(window.innerWidth);
            // },1000)
        }
    }
</script>

<style scoped lang="less">
    .main{
        min-width: 1024px;
        height: 100%;
    }
    .content{
        width: 100%;
        height: ~'calc( 100% - 64px)';
        top: 64px;
        left: 0px;
        z-index: 100;
    }
    .cont{
        width: 100%;
        height: ~'calc(100% - 38px)';
        left: 0px;
        top: 0px;
        padding-left: 200px;
        box-sizing: border-box;
        background: #f5f7f9;
        transition: .3s;
        &.hide{
            padding-left: 65px;
        }
    }

    .foot{
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 38px;
        padding-left: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f7f9;
        .center{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 24px;
                object-fit: cover;
            }
            span{
                font-size: 12px;
                color: #dedede;
                margin: 0px 20px;
                transform: scale(.8);
            }
            p{
                font-size: 12px;
                color: #727272;
            }
        }
    }

    .main.isDark{
        .cont{
            background: #0c0c0c;
            transition: .3s;
        }
        .foot{
            background: #202025;
            span,
            p{
                color: #c1c1c1;
            }
        }

        & /deep/ .el-loading-mask{
            background-color: rgba(0,0,0,.5);
            backdrop-filter: blur(10px);
        }

    }

    // 兼容一个很差的pad
    .badPad /deep/ {
        &>.pages{
            height: ~'calc(100% - 70px)';
            overflow: hidden;
        }
    }

    .badPad /deep/ .pages{
        .scroll{
            height: ~'calc(100vh - 230px )';
        }
    }

    .shadow{
        width: 100%;
        height: 1px;
        top: 63px;
        left: 0px;
        // box-shadow: 10px 5px 5px rgba(230, 233, 240, 0.5);
        box-shadow: 1px 2px 5px rgba(0,0,0,0.2);
        z-index: 105;
    }
</style>