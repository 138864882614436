<template>
    <div class="manyDay">
        
        <div class="print-cont pion-rel" v-for="index in Math.ceil((Object.keys(daysData).length  / 16))" :key="index" :style="{'margin-top' : !download ? '10px' : '0px' }">
            <div class="tiptitle">
                <div class="title">
                    <h2>{{$t('menu.list.Print.page8.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                        <span>{{$t('menu.list.Print.page8.times')}}：{{ coverage }}%</span>
                        <span>{{$t('menu.list.Print.page8.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page8.titUnit')}}</span>
                    </p>
                </div>
                <img class="logo" :src="logo" alt="">
            </div>

            <template v-if="daysLen > 16">

                <div class="table mt-15" v-for="idxs in Math.ceil((Object.keys(daysData).length  / 16 ))" :key="idxs" :class="[index == 2 && idxs == 2 ? ( daysData.length > 24 ? '' : 'none' ) : '' ]" :style="{ 'margin-top': Math.ceil((Object.keys(daysData).length  / 16 )) >= 2 ? '100px' : '' }">
                    <div class="tbody solid clear">
                        <div class="title text-center float-left solid-right">
                            <div class="td-title line-height-42 solid-bottom">
                                <p class="font-14">{{$t('menu.list.Print.page8.titles1')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <h2 class="font-weight">TIR</h2>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles2')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles3')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles4')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles5')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles6')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles7')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles8')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles9')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles10')}}</p>
                            </div>
                        </div>

                        <div class="for text-center float-left solid-right" v-for="(item,idx) in daysData" :key="idx" :style="{'border-right': daysData.length < 7 ? '1px solid #E5E5E5' : '' }" v-if=" idx < (( index + ( idxs + index ) - 2 ) * 8) && idx >= ( ( index + ( idxs + index ) - 3 ) * 8 )">
                            <div class="date pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 solid-bottom">{{ ( new Date(item.date).getMonth() + 1 ) < 10 ? ('0' + ( new Date(item.date).getMonth() + 1) ) : new Date(item.date).getMonth() + 1  }}-{{ new Date(item.date).getDate() < 10 ? ( '0' + new Date(item.date).getDate() ) : new Date(item.date).getDate() }}</p>
                            </div>
                            <div class="tir solid-bottom">
                                <div class="percentageBox flex pt-20 pb-20 pr-20 pl-20 justify-center align-center">
                                    <div class="column" v-if="item.tir">
                                        <div class="vh mt-5 radius-5" :style="{ 'height': (item.tir.very_high * 100 == 0 ? 1 : item.tir.very_high * 100 ) + '% !important' }"></div>
                                        <div class="h mt-5 radius-5" :style="{ 'height': (item.tir.high * 100 == 0 ? 1 : item.tir.high * 100) + '% !important' }"></div>
                                        <div class="nomal mt-5 radius-5" :style="{ 'height': (item.tir.common * 100 == 0 ? 1 : item.tir.common * 100) + '% !important' }"></div>
                                        <div class="l mt-5 radius-5" :style="{ 'height': (item.tir.low * 100 == 0 ? 1 : item.tir.low * 100 ) + '% !important' }"></div>
                                        <div class="vl mt-5 radius-5" :style="{ 'height': (item.tir.very_low * 100 == 0 ? 1 : item.tir.very_low * 100 ) + '% !important' }"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="percentage solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{  (( Number(item.tir.very_high) * 100 ) + (Number(item.tir.high) * 100) ).toFixed(0) }}%</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ ( Number(item.tir.common) * 100 ).toFixed(0) }}%</p>
                                <p class="font-14 line-height-35 solid-bottom">{{   parseInt( ( Number(item.tir.low) + Number(item.tir.very_low) ) * 100 ).toFixed(0) }}%</p>
                            </div>
                            <div class="num solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{ item.total }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.max).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.max).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.min).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.min).val).toFixed(1) }}</p>
                            </div>
                            <div class="total solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.mage).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.mage).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.sd).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.sd).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ item.cv }}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="table mt-15" v-for="idxs in Math.ceil((Object.keys(daysData).length  / 8 ))" :key="idxs" :class="[index == 2 && idxs == 2 ? ( daysData.length > 24 ? '' : 'none' ) : '' ]" :style="{ 'margin-top': Math.ceil((Object.keys(daysData).length  / 8 )) >= 2 ? '100px' : '' }">
                    <div class="tbody solid clear">
                        <div class="title text-center float-left solid-right">
                            <div class="td-title line-height-42 solid-bottom">
                                <p class="font-14">{{$t('menu.list.Print.page8.titles1')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <h2 class="font-weight">TIR</h2>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles2')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles3')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles4')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles5')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles6')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles7')}}</p>
                            </div>
                            <div class="td-title pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles8')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles9')}}</p>
                                <p class="font-14 line-height-30">{{$t('menu.list.Print.page8.titles10')}}</p>
                            </div>
                        </div>
                        <div class="for text-center float-left solid-right" v-for="(item,idx) in daysData" :key="idx" :style="{'border-right': daysData.length < 7 ? '1px solid #E5E5E5' : '' }" v-if=" idx < (( index + ( idxs + index ) - 2 ) * 8) && idx >= ( ( index + ( idxs + index ) - 3 ) * 8 )">
                            <div class="date pt-10 pr-10 pb-10 pl-10 solid-bottom">
                                <p class="font-14 solid-bottom">{{ ( new Date(item.date).getMonth() + 1 ) < 10 ? ('0' + ( new Date(item.date).getMonth() + 1) ) : new Date(item.date).getMonth() + 1  }}-{{ new Date(item.date).getDate() < 10 ? ( '0' + new Date(item.date).getDate() ) : new Date(item.date).getDate() }}</p>
                            </div>
                            <div class="tir solid-bottom">
                                <div class="percentageBox flex pt-20 pb-20 pr-20 pl-20 justify-center align-center">
                                    <div class="column" v-if="item.tir">
                                        <div class="vh mt-5 radius-5" :style="{ 'height': (item.tir.very_high * 100 == 0 ? 1 : item.tir.very_high * 100 ) + '% !important' }"></div>
                                        <div class="h mt-5 radius-5" :style="{ 'height': (item.tir.high * 100 == 0 ? 1 : item.tir.high * 100) + '% !important' }"></div>
                                        <div class="nomal mt-5 radius-5" :style="{ 'height': (item.tir.common * 100 == 0 ? 1 : item.tir.common * 100) + '% !important' }"></div>
                                        <div class="l mt-5 radius-5" :style="{ 'height': (item.tir.low * 100 == 0 ? 1 : item.tir.low * 100 ) + '% !important' }"></div>
                                        <div class="vl mt-5 radius-5" :style="{ 'height': (item.tir.very_low * 100 == 0 ? 1 : item.tir.very_low * 100 ) + '% !important' }"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="percentage solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{  (( Number(item.tir.very_high) * 100 ) + (Number(item.tir.high) * 100) ).toFixed(0) }}%</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ ( Number(item.tir.common) * 100 ).toFixed(0) }}%</p>
                                <p class="font-14 line-height-35 solid-bottom">{{   parseInt( ( Number(item.tir.low) + Number(item.tir.very_low) ) * 100 ).toFixed(0) }}%</p>
                            </div>
                            <div class="num solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{ item.total }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.max).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.max).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.min).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.min).val).toFixed(1) }}</p>
                            </div>
                            <div class="total solid-bottom">
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.mage).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.mage).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.sd).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.sd).val).toFixed(1) }}</p>
                                <p class="font-14 line-height-35 solid-bottom">{{ item.cv }}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="footText pion-abs" v-if="Math.ceil((Object.keys(daysData).length  / 16 )) < 2 || (Math.ceil((Object.keys(daysData).length  / 16 )) >= 2 && index == Math.ceil((Object.keys(daysData).length  / 16 )) )">
                <p>{{$t('menu.list.Print.page8.PS1')}}</p>
                <p>{{$t('menu.list.Print.page8.PS2')}}</p>
                <p>{{$t('menu.list.Print.page8.PS3')}}</p>
                <p>{{$t('menu.list.Print.page8.PS4')}}</p>
                <p>{{$t('menu.list.Print.page8.PS5')}}</p>
            </div>

            <div class="page-bottom pion-abs">
                <p>{{$t('menu.list.Print.page8.date')}}：{{toDay}}</p>
                <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
                <!-- {{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen / 16 )) + Math.ceil(daysLen / 4 ) + index }}/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen  / 16 )) + Math.ceil(daysLen / 4 ) + ( Math.ceil(daysLen / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
                <p>{{$t('menu.list.Print.page8.page')}}：{{ index + pageNum }}/{{ sum }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { time, getItem, changUnit } from '_js/Cache';
    export default {
        props:{
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            daysData: Array || Object,
            toDay: String,
            foodRecordRankLen: Number,
            daysLen: Number,
            parintList: Array,
            pageAdd: Number || String,
            isDefaultUnit: Number
        },
        data(){
            return{
                sum: 0,
                pageNum: 6,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            },
            pageAdd:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let doms = document.getElementsByClassName('print-cont'),
                        listArr = Array.from(doms),
                        cu = 0;
                    this.sum = doms.length;
                    listArr.map((item,index) =>{
                        if(item.parentNode.className == 'manyDay'){
                            if(cu == 0 ){
                                this.pageNum = index;
                            }
                            cu += 1;
                        }
                    })
                })
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>

    .manyDay{
        .table{

            .tbody{
                border-color: #8D8D8D;
                &>div{
                    width: ~'calc( (100% - 230px) / 8)';
                }
                .title{
                    border-color: #8d8d8d;
                    width: 230px;

                    .td-title{
                        border-color: #8D8D8D;
                        &:nth-last-child(1){
                            border: none;
                        }
                        &:nth-of-type(2){
                            height: 166px;
                            line-height: 166px;
                        }
                    }
                }

                .for{
                    border-color: #E5E5E5;

                    &:nth-of-type(9){
                        border: none;
                    }

                    &>div{
                        border-color: #E5E5E5;

                        &:nth-last-child(1){
                            border: none;
                        }
                        p{
                            border-color: #E5E5E5;
                            
                            &:nth-child(1){
                                margin-top: 2px;
                            }

                            &:nth-last-child(1){
                                border: none;
                                margin-top: 1.5px;
                            }
                        }
                    }

                    .tir{
                        .column{
                            margin-top: 0px;
                            width: 45%;
                            height: 125px;
                            padding-bottom: 25px;
                        }
                    }
                }
            }
        }

        .footText{
            width: ~'calc(100% - 110px)';
            bottom: 130px;

            p{
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    .percentageBox{
        .column{
            width: 25%;
            height: 180px;
            margin-top: -25px;
            &>div{
                width: 100%;
                height: 10px;
                min-height: 1px;
            }
            .vh{
                margin-top: 0px !important;
                background: #F5B940;
            }
            .h{
                background: #FBE46C;
            }
            .nomal{
                height: calc(~' 100% - 60px');
                background: #57BB9D;
            }
            .l{
                background: #EE7373;
            }

            .vl{
                background: #EE7373;
            }
        }
    }
</style>