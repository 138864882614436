<template>
    <div class="Task pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" v-loading="loading">
            <template v-if="!loading">
                <el-tabs class="pion-rel" v-model="progress">
                    <el-tab-pane label="未完成任务" name="1" />
                    <el-tab-pane label="已完成任务" name="5" />
                    <el-tab-pane label="已过期任务" name="-1" />
                </el-tabs>
                <div class="tags flex font-12">
                    <div class="tagItem radius-5 pt-5 pb-5 pl-5 pr-5 mr-20" :class="[ item.active ? 'active' : '' ]" @click="check(item)" v-for="(item,index) in tags" :key="index">{{ item.label }}</div>
                </div>
                <div class="list mt-20" v-if="taskList && taskList && taskList.length">
                    <div class="flex flex-wrap">
                        <div class="item pt-12 pb-12 pl-12 pr-12 radius-5 solid pion-rel mr-18 mb-20" @click="addActivity(item.type,item.uid)" v-for="(item,index) in taskList" :key="index">
                            <img class="icon pion-abs" v-if="item.type == 5" src="@/assets/images/icons/du.png" alt="">
                            <img class="icon pion-abs" v-if="item.type == 4" src="@/assets/images/icons/u.png" alt="">
                            <div class="status font-12 font-weight pion-abs line-height-18" :class="[ item.progress > 1 ? 'grey' : '' ]">{{ item.progress | statusToText }}</div>
                            <div class="title font-14 font-weight">{{ item.title }}</div>
                            <div class="time font-12 mt-5 mb-20 line-height-18">{{ item.task_date }}</div>
                            <div class="patient flex align-center">
                                <img class="ava mr-5 pson-r-50" :src="item.avatar" :onerror="notHeaders"/>
                                <div class="name font-14 font-weight text-cut">{{ item.agency_nickname }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="pages flex justify-center">
                        <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :total="count" :page-size="30"></el-pagination>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="box-center">
                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                        <p>暂无数据</p>
                    </div>
                </div>
            </template>
        </div>
        <AddPlan :parameter="parameter" :Dark="Dark" @closePlan="close" />
    </div>
</template>

<script>
    import { getTaskList } from 'quest'; 
    import { AddPlan } from '_c/Model/index';
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        data(){
            return{
                loading: true,
                taskList:[],
                count: 0,
                page: 1,
                type: '',
                progress: '1',
                hiddenPage: true,
                tags:[
                    {
                        label:'每日控糖方案', 
                        type:4, 
                        active:false
                    },
                    {
                        label:'每日数据解读', 
                        type:5,
                        active:false
                    }
                ],
                statusToText: '',
                parameter:{
                    from:'details',
                    show: false,
                    id: 0,
                },
                notHeaders: 'this.src="' + require('@/assets/images/error/notheader.png') + '"'
            }
        },
        components:{
            AddPlan
        },
        watch:{
            progress(){
                this.page = 1;
                this.loading = true;
                this.currentPage = 1;
                this.getTaskList();
            }
        },
        filters:{
            statusToText(value){
                if(value == 1){
                    return '未完成'
                }else if(value == 5){
                    return '已完成'
                }else if(value == -1){
                    return '已过期'
                }
            }
        },
        methods:{
            getTaskList(){
                getTaskList({
                    page: this.page,
                    type:this.type,
                    progress: Number(this.progress)
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.taskList = res.data.data.list,
                        this.count = res.data.data.count;
                        this.loading = false;
                    }
                })
            },
            selectPage(page){
                this.page = page;
                this.getTaskList();
            },
            check(tag){
                let activeArr = [];
                this.tags.forEach(item=>{
                    if(item.type == tag.type){
                        item.active = !item.active;
                    }
                    if(item.active){
                        activeArr.push(item.type)
                    }
                })
                this.type = activeArr.join(',');
                this.page = 1;
                this.currentPage = 1;
                this.getTaskList();
            },
            addActivity(type,uid){
                console.log(uid);
                if(this.progress == 1){
                    if(type == 4 ){
                        this.parameter.id = uid;
                        this.parameter.show = true;
                    }else{
                        this.$router.push({
                            name: "PatientDetails",
                            query: { 
                                id: uid
                            }
                        });
                    }
                }
            },

            close(val){
                window.isCloseHint = false;
                this.parameter.show = false;
                this.parameter.id = 0;
                if(!val){
                    for(let i = 0; i < 7 ; i++ ){
                        localStorage.removeItem('day' + i);
                    }
                }
            }
        },
        mounted(){
            const [report,plan] = [this.$route.query.report || '', this.$route.query.plan || ''] ;
            if(report){
                this.check(this.tags[1])
            }else if(plan){
                this.check(this.tags[0])
            }else{
                this.getTaskList()
            }
        }
    }
</script>

<style scoped lang="less">

    .Task{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);

            .tags{
                color: #9F9F9F;

                .tagItem{
                    cursor: pointer;
                    min-width: 80px;
                    background-color: #F8F8F8;
                    color: #9F9F9F;
                }
                .tagItem.active{
                    background-color: #13BE9B;
                    color: var(--white);
                }
            }

            .item{
                cursor: pointer;
                width: ~'calc( ( 100% - 108px ) / 6 )';
                background: var(--white);
                border-color:#EBEBEB;

                .title{
                    color: #242424;
                }
                .time{
                    color: #9F9F9F;
                }
                .patient{

                    .ava{
                        width: 25px;
                        height: 25px;
                    }

                    .name{
                        color: #242424;
                        line-height: 14px;
                        width: 60px;
                    }
                }
                .icon{
                    top: 12px;
                    right: 12px;
                    width: 21px;
                    height: 21px;
                }
                .status{
                    height: 18px;
                    color: var(--bloodSugarLow);
                    bottom: 12px;
                    right: 12px;
                }
                .status.grey{
                    color: var(--defalutActiveColor);
                }
            }

        }

        .scroll /deep/ .el-tabs{

            .el-tabs__nav-wrap:after{
                height: 1px;
                background-color: #dcdee2;
            }

            .el-tabs__nav-scroll{
                padding-left: 20px;
            }

            .el-tabs__active-bar{
                height: 2px;
                background-color: var(--defalutColor);
            }
            
            .el-tabs__item.is-active{
                font-weight: 100;
                color: var(--defalutColor);
            }
            
            .el-tabs__item{
                transition: .3s;
            }
            
            .el-tabs__item:hover{
                transition: .3s;
                color: var(--defalutColor);
            }

            .el-tabs__content{
                overflow: visible;
            }
        }

        .scroll /deep/ .pages{
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background: none;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }

        &.dark{
            .scroll{
                background: #202025 !important;
            }

            .scroll /deep/ .el-tabs{

                .el-tabs__item{
                    color: #c1c1c1 !important;
                    &.is-active{
                       color: var(--defalutColor) !important; 
                    }
                }

                .el-tabs__nav-wrap:after{
                    background-color: #2c2c2e;
                }

            }
            .tagItem{
                background-color: unset !important;
                border: 1px solid #2c2c2c;
                color: #9f9f9f !important;;
                &.active{
                    color: var(--defalutColor) !important;
                    border-color: var(--defalutColor) !important;
                }
            }
            .item{
                background: unset !important;
                color: #c1c1c1 !important;
                border-color: #2c2c2c !important;

                .title{
                    color: #c1c1c1 !important;
                }
                .name{
                    color: #c1c1c1 !important;
                }
            }

            & /deep/ .pages{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }
        }

    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .Task{
                .list{
                    .item{
                        width: ~'calc( ( 100% - 108px ) / 5 )';
                    }
                }
            }
        }
    }
</style>