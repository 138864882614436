<template>
    <div class="print-cont cycle pion-rel" v-if="loading" :style="{'margin-top' : !download ? '10px' : '0px' }">
        <div class="tiptitle">
            <div class="title">
                <h2>{{$t('menu.list.Print.page2.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                    <span>{{$t('menu.list.Print.page2.times')}}：{{ coverage }}%</span>
                    <span>{{$t('menu.list.Print.page2.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page2.titUnit')}}</span>
                </p>
            </div>
            <img class="logo" src="@/assets/images/print/pring-logo.png" alt="">
        </div>

        <div class="cont-tip solid-top mt-20 pt-15 flex">
            <div class="averageBooldSugar solid radius-20 pt-20 pb-20">
                <h4 class="pl-20 pr-20 font-23">{{$t('menu.list.Print.page2.avg')}}</h4>
                <p><span class="font-weight">{{ changUnit(isDefaultUnit,avgBloodSugar).val.toFixed(0) }}</span>{{changUnit(isDefaultUnit).unit }}</p>
                <p class="percentage pt-20 pb-20 pl-20 pr-20 font-18 solid-top">{{$t('menu.list.Print.page2.hemoglobin')}}：{{estimateAlbumen}}%</p>
            </div>
            <div class="booldFluctuating solid radius-20 pt-20 pb-20 ml-20 mr-20">
                <h2 class="font-23 pl-20 pr-20">{{$t('menu.list.Print.page2.volatility')}}</h2>
                <div class="MAGE-SD-CV flex">
                    <div class="MAGE flex justify-center align-center text-center solid-right">
                        <div class="center">
                            <p class="font-18 font-weight">LAGE</p>
                            <p class="font-14">{{changUnit(isDefaultUnit).unit }}</p>
                            <h4 class="font-weight mt-10 mb-10">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,LAGE).val).toFixed(0) : Number(changUnit(isDefaultUnit,LAGE).val).toFixed(1) }}</h4>
                            <span class="font-16"> &lt; {{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,4.4).val).toFixed(0) : Number(changUnit(isDefaultUnit,4.4).val).toFixed(1) }} </span>
                        </div>
                    </div>
                    <div class="SD flex justify-center align-center text-center solid-right">
                        <div class="center">
                            <p class="font-18 font-weight">SD</p>
                            <p class="font-14">{{changUnit(isDefaultUnit).unit }}</p>
                            <h4 class="font-weight mt-10 mb-10">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,SD).val).toFixed(0) : Number(changUnit(isDefaultUnit,SD).val).toFixed(1) }}</h4>
                            <span class="font-16">{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,0.5).val).toFixed(0) : Number(changUnit(isDefaultUnit,0.5).val).toFixed(1) }}-{{ isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,1.4).val).toFixed(0) : Number(changUnit(isDefaultUnit,1.4).val).toFixed(1) }}</span>
                        </div>
                    </div>
                    <div class="CV flex justify-center align-center text-center">
                        <div class="center">
                            <p class="font-18 font-weight">CV</p>
                            <p class="font-14">%</p>
                            <h4 class="font-weight mt-10 mb-10">{{ CV }}</h4>
                            <span class="font-16">19-25%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="percentageBox solid radius-20 pt-20 pb-20 pl-20 pr-20 flex justify-center align-center">
                <div class="column">
                    <div class="vh mt-5 radius-5" :style="{ 'height': TIR.very_high * 100 + '%'}"></div>
                    <div class="h mt-5 radius-5" :style="{ 'height': TIR.high * 100 + '%'}"></div>
                    <div class="nomal mt-5 radius-5" :style="{ 'height': TIR.common * 100 + '%'}"></div>
                    <div class="l mt-5 radius-5" :style="{ 'height': TIR.low * 100 + '%'}"></div>
                    <div class="vl mt-5 radius-5" :style="{ 'height': TIR.very_low * 100 + '%'}"></div>
                </div>
                <div class="data-text ml-20 font-weight">
                    <p class="font-14">{{ Number(TIR.very_high * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.vhigh')}}</p>
                    <p class="font-18 mt-15">{{ Number(TIR.high * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.high')}}</p>
                    <h4 class="mt-15">{{ Number(TIR.common  * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.normal')}}</h4>
                    <p class="font-18 mt-15">{{ Number(TIR.low * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.low')}}</p>
                    <p class="font-14 mt-15">{{ Number(TIR.very_low * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.vlow')}}</p>
                </div>
            </div>
        </div>

        <div class="toptip flex mt-20" v-if="days < 15">
            <div class="row-6 chart flex solid pt-20 pb-20 pl-20 pr-20 radius-20">
                <div class="chat-name pl-20 flex flex-wrap solid-right">
                    <h4 class="font-23 pt-20">{{$t('menu.list.Print.page2.insulin')}}</h4>
                    <p class="font-weight">{{ dayAvgInsulin && dayAvgInsulin != 0 ? dayAvgInsulin : '--' }}<span class="font-14 ml-10">U/{{$t('menu.list.Print.page2.days')}}</span></p>
                </div>
                <div class="charts flex align-center">
                    <InsulinPie :dayAvgLongInsulin="dayAvgLongInsulin" :dayAvgFoodInsulin="dayAvgFoodInsulin" />
                    <div class="insulin-data flex flex-wrap pl-10">
                        <div class="long-acting">
                            <div class="title flex align-center">
                                <span class="block radius-50 mr-10"></span>
                                <p class="font-20">{{$t('menu.list.Print.page2.longInsulin')}}</p>
                            </div>
                            <div class="data flex pl-20 justify-between">
                                <p>{{ dayAvgLongInsulin && Number(dayAvgLongInsulin) != 0 ? dayAvgLongInsulin.toFixed(0) : '--' }}
                                    <span class="font-18" v-if="Number(dayAvgLongInsulin) != 0">U</span>
                                </p>
                                <p>{{ Number(dayAvgLongInsulin) == 0 ? '--' : ( Number(dayAvgLongInsulin) / ( Number(dayAvgLongInsulin) + Number(dayAvgFoodInsulin) ) * 100).toFixed(0) }}
                                    <span class="font-18" v-if="Number(dayAvgLongInsulin) != 0">%</span>
                                </p>
                            </div>
                        </div>
                        <div class="quick-acting">
                            <div class="title flex align-center">
                                <span class="block radius-50 mr-10"></span>
                                <p class="font-20">{{$t('menu.list.Print.page2.MealInsulin')}}</p>
                            </div>
                            <div class="data flex pl-20 justify-between">
                                <p>{{ dayAvgFoodInsulin && Number(dayAvgFoodInsulin) != 0 ? Number(dayAvgFoodInsulin).toFixed(0) : '--'}}
                                    <span class="font-18" v-if="dayAvgFoodInsulin != 0">U</span>
                                </p>
                                <p>{{ Number(dayAvgFoodInsulin) == 0 ? '--' : (Number(dayAvgFoodInsulin) / ( Number(dayAvgLongInsulin) + Number(dayAvgFoodInsulin) ) * 100).toFixed(0) }}
                                    <span class="font-18" v-if="Number(dayAvgFoodInsulin) != 0">%</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-6 everyDay flex ml-20">
                <div class="row-6 water flex flex-wrap solid radius-20 pt-20 pb-20 pl-20 pr-20 align-center">
                    <h4>{{$t('menu.list.Print.page2.carbohydrate')}}</h4>
                    <div class="conts">
                        <p class="font-weight">{{ dayAvgCarb && dayAvgCarb != 0 ? dayAvgCarb : '--' }}
                            <span class="font-14 font-normal ml-10" v-if="dayAvgCarb != 0">{{$t('menu.list.Print.page2.unit')}}</span>
                        </p>
                    </div>
                </div>
                <div class="row-6 sport flex flex-wrap solid radius-20 pt-20 pb-20 pl-20 pr-20 align-center ml-20">
                    <h4>{{$t('menu.list.Print.page2.sport')}}</h4>
                    <div class="conts">
                        <p class="font-weight">{{ dayAvgCalorie && dayAvgCalorie != 0 ? dayAvgCalorie : '--' }}
                            <span class="font-14 font-normal ml-10" v-if="dayAvgCalorie != 0">{{$t('menu.list.Print.page2.sunit')}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="chart-list" :class="[days < 15 ? '' : 'mt-20 pt-20']">
            <div class="chart-area pion-rel mt-20">
                <h4 class="pion-rel">{{$t('menu.list.Print.page2.AGP')}}</h4>
                <div class="example pion-abs flex">
                    <div class="colorBox flex align-center">
                        <hr class="color" />
                        <p class="font-18 ml-5 font-weight">50%</p>
                    </div>
                    <div class="colorBox flex align-center ml-10">
                        <hr class="color" />
                        <p class="font-18 ml-5 font-weight">25%-75%</p>
                    </div>
                    <div class="colorBox flex align-center ml-10">
                        <hr class="color" />
                        <p class="font-18 ml-5 font-weight">10%-90%</p>
                    </div>
                    <div class="colorBox flex align-center ml-10">
                        <div>
                            <hr class="line d-bottom float-left" />
                            <hr class="line d-bottom float-left mt-20" />
                        </div>
                        <p class="font-18 ml-5 font-weight">{{$t('menu.list.Print.page2.minmax')}}</p>
                    </div>
                </div>
                <div class="chart-line" v-if="envelope != null && userInfo != null">
                    <ACGChratLine :value="envelope" :userInfo="userInfo" :isDefaultUnit="isDefaultUnit" />
                </div>
            </div>
        </div>
        <div class="everydayLine">
            <div class="title mt-20 mb-20">
                <h4>{{$t('menu.list.Print.page2.footTitle')}}</h4>
            </div>
            <div class="flex justify-around">
                <template v-for="item in columns">
                    <div class="table text-center">
                        <div class="title justify-center pt-5 pb-5">
                            {{ getWeek(item.title) }}
                        </div>
                        <div class="line mt-10" v-if="item.title < new Date(days_data[0].date).getDay()"></div>
                        <template v-for="items in days_data">
                            <div class="line mt-10 charts flex justify-center align-center pion-rel solid radius-5" v-if=" item.title == items.week_num">
                                <p class="date pion-abs font-12">
                                    {{ timeGet(items.date)}}
                                </p>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <div class="hr pion-abs"></div>
                                <SectionLine v-if="items.line" :value="items.line" :target_low="userInfo.target_low"  :target_high="userInfo.target_high" :isDefaultUnit="isDefaultUnit" />
                            </div>
                        </template>

                    </div>
                </template>
            </div>
        </div>

        <div class="page-bottom pion-abs">
            <p>{{$t('menu.list.Print.page2.date')}}：{{toDay}}</p>
            <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : lang == 'tc' ? '糖動' : '糖动' }}CGMCARE</p>
            <p>{{$t('menu.list.Print.page2.page')}}：2/{{ 4 + ( days > 7 ? Math.ceil((daysData.length  / 16 )) : Math.ceil((daysData.length  / 4 )) ) + (days < 16 && days > 8 ? 1 : 0 ) }}</p>
        </div>

    </div>
</template>

<script>
    import { InsulinPie, ACGChratLine, SectionLine } from '_c/print';
    import { time, changUnit } from '_js/Cache';
    export default {
        props:{
            download: Boolean,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            avgBloodSugar: Number,
            estimateAlbumen: Number,
            LAGE: Number,
            SD: Number,
            CV: Number,
            TIR: Object,
            dayAvgInsulin: Number,
            dayAvgLongInsulin: Number,
            dayAvgFoodInsulin: Number,
            dayAvgCarb: Number,
            dayAvgCalorie: Number,
            envelope: Object,
            userInfo: Object,
            daysData: Array || Object,
            toDay: String,
            foodRecordRankLen: Number,
            isDefaultUnit: Number,
            lang: String
        },
        components:{
            InsulinPie,
            ACGChratLine,
            SectionLine
        },
        data(){
            return{
                columns: [
                    {
                        title: 0,
                        slot: 'Sun',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 1,
                        slot:'Mon',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 2,
                        slot: 'Tue',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 3,
                        slot: 'Wed',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 4,
                        slot: 'Thu',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 5,
                        slot:'Fri',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 6,
                        slot: 'Sat',
                        align: "center",
                        chartLine:[]
                    }
                ],
                days_data: [],
                loading: false
            }
        },
        methods:{
            getWeek(num){
                switch(num){
                    case 0 :
                        return this.lang == 'en' ? 'Sunday' : '日';
                    case 1 :
                        return this.lang == 'en' ? 'Monday' : '一';
                    case 2 :
                        return this.lang == 'en' ? 'Tuesday' : '二';
                    case 3 :
                        return this.lang == 'en' ? 'Wednesday' : '三';
                    case 4 :
                        return this.lang == 'en' ? 'Thursday' : '四';
                    case 5 :
                        return this.lang == 'en' ? 'Friday' : '五';
                    case 6 :
                        return this.lang == 'en' ? 'Saturday' : '六';
                }
            },
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            roleData(data,days,startDay){
                for(let i = 0; i < days; i++){
                    let times = ( Number(startDay) + i  * 24 * 60 * 60 ) * 1000,
                        day = time( new Date(times) , 'YYYY-MM-DD');
                    this.days_data.push({
                        date: day,
                        week_num: new Date(day).getDay(),
                        avg: ''
                    })
                    data.map( item => {
                        if(day == item.date){
                            this.days_data[i] = item;
                        }
                    })
                    if( i + 1 == days){
                        this.loading = true;
                    }
                }
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        },
        mounted(){
            this.roleData(this.daysData,this.days,this.bindTime);
            // let str = null;
            // this.daysData.map( (item,idx) =>{
            //     let day_null = ( new Date(this.daysData[idx + 1] ? this.daysData[idx + 1].date : '' ) - new Date(item.date) ) / (24*60*60) / 1000;
            //     if(day_null > 1){
            //         console.log(item);
            //         for( let i = 0 ; i < day_null; i++ ){
            //             item.weekNum = Number(item.week_num) + i;
            //             item.date = '';
            //             str = item;
            //             this.days_data.push(str);
            //         }
            //     }else{
            //         this.days_data.push( item );
            //     }
            // })
            // console.log(this.days_data);
        }
    }
</script>

<style lang="less" scoped>

    .cont-tip{
        border-color:#e5e5e5;

        &>div{
            width: calc(~' (100% - 40px) / 3');
            border-color: #e5e5e5;
            height: 260px;
        }

        .averageBooldSugar{
            h4{
                color: #000;
            }
            p:nth-of-type(1){
                font-size: 42px;
                font-weight: normal;
                padding: 20px 30px;
                color: #000;
                span{
                    font-size: 72px;
                }
            }

            .percentage{
                border-color: #e5e5e5;
                color: #000;
            }
        }

        .booldFluctuating{
            h2{
                color: #000;
            }
            
            .MAGE-SD-CV{
                height: 180px;
                padding-top: 35px;
                &>div{
                    flex: 1;
                    p:nth-of-type(1){
                        color: #000;
                    }
                    p:nth-of-type(2){
                        color: #8d8d8d;
                    }
                    h4{
                        font-size: 32px;
                        color: #000;
                    }
                    span{
                        color: #8d8d8d;
                    }
                }

                .MAGE,
                .SD{
                    border-color: #e5e5e5;
                }
            }
        }

        .percentageBox{
            .column{
                width: 25%;
                height: 180px;
                margin-top: -25px;
                &>div{
                    width: 100%;
                    height: 10px;
                    min-height: 1px;
                }
                .vh{
                    margin-top: 0px !important;
                    background: #F5B940;
                }
                .h{
                    background: #FBE46C;
                }
                .nomal{
                    height: calc(~' 100% - 60px');
                    background: #57BB9D;
                }
                .l{
                    background: #EE7373;
                }

                .vl{
                    background: #EE7373;
                }
            }
            .data-text{
                color: #000;

                &>p:nth-of-type(1),
                &>p:nth-last-of-type(1){
                    font-size: 14px;
                }

                &>p:nth-of-type(2),
                &>p:nth-last-of-type(2){
                    font-size: 18px;
                }

                &>h4{
                    font-size: 28px;
                }
            }
        }
    }

    .toptip{
        width: 100%;
        height: 225px;
        .row-6{
            width: 50%
        }
        .chart{
            width: calc(~ '50% - 10px');
            height: 100%;
            border-color: #e5e5e5;

            .chat-name{
                width: 200px;
                border-color: #e5e5e5;
                h4{
                    width: 100%;
                    color: #000;
                }
                p{
                    width: 100%;
                    font-size: 34px;
                    color: #000;
                    span{
                        font-weight: normal;
                    }
                }
            }
            .charts{
                width: 65%;
                height: 100%;
                .insulin-data{
                    width: 50%;
                    .long-acting,
                    .quick-acting{
                        width: 100%;
                        .title{
                            span{
                                width: 10px;
                                height: 10px;
                                background: #2055A6;
                            }
                        }
                        .data{
                            p{
                                font-size: 25px;
                                font-weight: 700;
                                span{
                                    color: #8D8D8D;
                                    margin-left: -8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .everyDay{
            width: calc(~ '50% - 10px');
            &>div{
                width: calc(~ '50% - 10px');
                height: 100%;
                border-color: #e5e5e5;
            }
            .water,.sport{
                h4{
                    font-size: 28px;
                    color: #000;
                    width: 100%;
                }
                .conts{
                    p{
                        font-size: 32px;
                        color: #000;
                    }
                    span{
                        color: #8d8d8d;
                    }
                }
            }
        }
    }

    .chart-area{
        width: 100%;
        h4{
            font-size: 29px;
            color: #000;
            letter-spacing: 1px;
        }

        .example{
            right: 0px;
            top: 15px;
            width: 60%;
            .colorBox{
                &:nth-of-type(1) .color{    
                    width: 70px;
                    height: 2px;
                    background: #515151;
                }
                &:nth-of-type(2) .color{    
                    width: 70px;
                    height: 25px;
                    background: #a3d0f5;
                }
                &:nth-of-type(3) .color{    
                    width: 70px;
                    height: 25px;
                    background: #cde2f5;
                }
                &:nth-of-type(3) .color{    
                    width: 70px;
                    height: 25px;
                    background: #cde2f5;
                }
                .line{
                    width: 75px;
                    border-color: #979797;
                }
                p{
                    width: 100%;
                }
            }
        }
        .chart-line{
            width: 100%;
            height: 400px;
            position: relative;
            margin-top: 25px;
        }
    }

    .everydayLine{
        .title{
            h4{
                font-size: 29px;
                color: #000;
                letter-spacing: 1px;
            }
        }
        .table{
            flex: 1;
            .title{
                background-color: #fafafa;
            }
            .line{
                width: 155px;
                height: 95px;
                .date{
                    top: 10px;
                    left: 10px;
                    z-index: 10000;
                }

                .hr{
                    width: 0.5px;
                    height: 5px;
                    background-color: #c4c4c4;
                    z-index: 100000;
                }

                .hr:nth-of-type(1){
                    top: 0px;
                    left: ~'calc(50% - 0.5px)';
                }

                .hr:nth-of-type(2){
                    top: 25%;
                    left: 2px;
                    transform: rotate(90deg);
                }

                .hr:nth-of-type(3){
                    top: 50%;
                    left: 2px;
                    transform: rotate(90deg);
                }

                .hr:nth-of-type(4){
                    top: 75%;
                    left: 2px;
                    transform: rotate(90deg);
                }

                .hr:nth-of-type(5){
                    bottom: 0px;
                    left: ~'calc(50% - 0.5px)';
                }

                .hr:nth-of-type(6){
                    top: 25%;
                    right: 2px;
                    transform: rotate(90deg);
                }

                .hr:nth-of-type(7){
                    top: 50%;
                    right: 2px;
                    transform: rotate(90deg);
                }

                .hr:nth-of-type(8){
                    top: 75%;
                    right: 2px;
                    transform: rotate(90deg);
                }

                &.charts{
                    border-color: #c4c4c4;
                }
                & /deep/ .ChartsLine{
                    width: 150px;
                    height: 105px;
                }
            }
        }
    }

</style>