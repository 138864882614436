<template>
    <div class="ChartsLine">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "../Charts/theme.json";
    import { on, off, time, dataGolay } from "_js/Cache";
    echarts.registerTheme("tdTheme", tdTheme);
    let dom = null;

    export default {
        props:{
            value: {
                type: Object || Array,
                default: () => {
                    return [];
                },
                required: false,
            },
            specialArr: Array,
            type:Boolean,
            target_high:{
                default:null
            },
            target_low:{
                default:null
            },
            isPDF: Boolean,
            hideXY: Boolean,
            index: Number,
            longPass: Boolean,
            lineMax: Number,
            isDefaultUnit: Number
        },
        data() {
            return {
                isReady: false,
                isDark: false,
                imgData: ''
            };
        },
        methods:{

            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },

            init() {
                let __self__ = this,
                    values = __self__.isPDF == true ? dataGolay(__self__.value.value) : '';
                let xAxisData = __self__.isPDF == true ? '' : __self__.value.map(i => {
                    return i.time;
                });
                let yAxisData1 = __self__.isPDF == true ? __self__.value.time.map( (item,index) => {
                    return [
                        item, values[index]
                    ];
                }) : __self__.value.map( i => {
                    return i.value;
                });
                let color = ["#16CCA6", "#00CA69"];

                let option = {
                    animation: false,
                    color: color,
                    [__self__.isPDF ? '' : 'tooltip']: __self__.isPDF ? '' : {
                        trigger: "axis",
                    },
                    visualMap: !__self__.hideXY ? {
                        top: 0,
                        left: 0,
                        precision:1,
                        show: false,
                        orient:'horizontal',
                        pieces: __self__.hideXY ? [] : [
                            {
                                gte: 0,
                                lte: Number(__self__.isDefaultUnit == 1 ? __self__.target_low * 18 : __self__.target_low),
                                color: '#ED1B24'
                            },
                            {
                                gte: Number(__self__.isDefaultUnit == 1 ? __self__.target_low * 18 : __self__.target_low),
                                lte: Number(__self__.isDefaultUnit == 1 ? __self__.target_high * 18 : __self__.target_high),
                                color: '#16cca6'
                            },
                            {
                                gte: Number(__self__.isDefaultUnit == 1 ? __self__.target_high * 18 : __self__.target_high),
                                color: '#FFA901'
                            }
                        ],
                    } : null,
                    grid: {
                        top: __self__.longPass == null ? 2 : 40,
                        bottom: 20,
                        left:  __self__.longPass == false ? '3%' : ( (__self__.index + 1 ) % 4 != 0 ? 0 : __self__.longPass == true ? 0 : 15 ),
                        right: (__self__.index + 1 ) % 4 != 0 ? 0 : __self__.longPass ? 20 : 15,
                        width: __self__.longPass == false ? '94%' : '',
                        height: __self__.longPass == null ? '70%' : ''
                    },
                    xAxis: [
                        {
                            show: __self__.hideXY ? false : true,
                            type: __self__.isPDF == true ? 'time' : "category",
                            offset: -1,
                            boundaryGap: false,
                            axisLabel: {
                                interval: __self__.isPDF ? 47  :  (__self__.type ? 5 : 15 ) ,
                                formatter: __self__.isPDF == true ? (val) =>{
                                    return time(new Date(val),'h:m')
                                } : '{value}',
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#333",
                                    fontSize: 10
                                }
                            },
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    type: "dashed",
                                    color: "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#D9D9D9"
                                }
                            },
                            data: xAxisData
                        }
                    ],
                    yAxis: [
                        {
                            show: __self__.hideXY ? false : true,
                            position: "right",
                            offset: -4,
                            type: "value",
                            interval: __self__.isDefaultUnit == 0 ? 4 : 72,
                            min:0,
                            max(value){
                                const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                                 return __self__.isPDF ? (__self__.isDefaultUnit && __self__.isDefaultUnit == 1 ? ( parseInt( __self__.lineMax) + (4 - parseInt( __self__.lineMax) % 4 ) ) * 18 : ( parseInt( __self__.lineMax) + (4 - parseInt( __self__.lineMax) % 4 ) ) ) : val;
                            },

                            axisLabel: {
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#666",
                                    fontSize: 10
                                }
                            },
                            nameTextStyle: {
                                color: "#666",
                                fontSize: 10,
                                lineHeight: 40
                            },
                            // 分割线
                            splitLine: {
                                lineStyle: {
                                    color: __self__.isDark && !__self__.isPDF ? '#2c2c2e' : "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: __self__.isPDF ? '' : "血糖",
                            type: "line",
                            smooth: true,
                            connectNulls: true,
                            symbolSize: 2,
                            zlevel: 3,
                            symbol: "circle", // 数据交叉点样式
                            markArea: {
                                itemStyle:{
                                    color: __self__.hideXY ? '#DEDEDE' : __self__.hexToRgba('#5EFF72',0.1)
                                },
                                data: [
                                    [{
                                    yAxis: __self__.isDefaultUnit == 1 ? __self__.target_low * 18 :  __self__.target_low||0
                                    }, {
                                    yAxis: __self__.isDefaultUnit == 1 ? __self__.target_high * 18 : __self__.target_high||0
                                    }]
                                ],
                                animation: true
                            },
                            itemStyle: __self__.hideXY ? {
                                color: "#000"
                            } : {},
                            lineStyle: __self__.hideXY ? {
                                normal:{
                                    color: '#000'
                                }
                            } : {},
                            markPoint: {
                                data : __self__.specialArr,
                            },
                            data: yAxisData1
                        },
                    ]
                };
                dom = echarts.init(this.$refs.dom, "tdTheme");
                dom.setOption(option);
                this.imgData = dom.getDataURL({
                    pixelRatio: 2
                });
                dom.dispose();
                on(window, "resize", this.resize);
            },
            domWidthListener(has){
                // 直接导入使用，也可以在main.js中引入
                let elementResizeDetectorMaker = require("element-resize-detector")
                // 创建实例，无参数
                let erd = elementResizeDetectorMaker();
                if(has){
                    erd.listenTo(document.getElementsByClassName("main-content-con"), (element) => {
                        this.width = element.offsetWidth;
                        this.value = this.value;
                        if(dom != null && dom != 'null' ){
                            this.resize();
                        }
                    });
                }
            }
        },
        mounted(){
            this.isReady = true;
            setTimeout( () => {
                this.init()
            },200)
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<style lang="less" scoped>
    .ChartsLine{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>