<template>
    <div class="everyDay" :class="[!Dark ? '' : 'dark']" v-loading="!loading">
        <div class="dayDate">
            <div class="date flex pion-rel justify-center align-center">
                <div class="info pion-abs" v-if="allData.is_cgm == 1 && allData.device_info != '' && allData.device_info">
                    <img src="@/assets/images/Patient/source1.png" v-if="Number(allData.device_info.source) == 1" alt="">
                    <img src="@/assets/images/Patient/source2.png" v-if="Number(allData.device_info.source) == 2" alt="">
                    <img src="@/assets/images/Patient/source3.png" v-if="Number(allData.device_info.source) == 3" alt="">
                    <img src="@/assets/images/Patient/source4.png" v-if="Number(allData.device_info.source) == 4" alt="">
                    <img src="@/assets/images/Patient/source5.png" v-if="Number(allData.device_info.source) == 6" alt="">
                    <div class="center-box">
                        <div class="name">
                        检测设备：<p>{{allData.device_info.device_type}}</p>
                        </div>
                        <div class="number">
                        设备编号： <p>{{allData.device_info.device_name}}</p>
                        </div>
                    </div>
                </div>
                <div class="notInfo" v-else></div>
                <div class="select font-weight">
                    <i class="el-icon-arrow-left font-weight mr-10" @click="changeDateByIcon(-1)"></i>
                    {{today}}
                    <i class="el-icon-arrow-right font-weight ml-10" @click="changeDateByIcon(1)"></i>
                </div>
                <el-date-picker class="pion-abs dateSelect" v-model="selectDate" :append-to-body="false" align="right" :picker-options="pickerOptions" type="date" placeholder="选择日期" @change="selectDay" clearable />
            </div>
            <div class="isCgm" v-if="allData.is_cgm == 1">
                <div class="item xuetangzhi">
                    <div class="title" v-if="date_time == today">{{allData.newest_blood_sugar.create_time_str ? allData.newest_blood_sugar.create_time_str : '--' }}</div>
                    <div class="title" v-else>{{today.split('-')[1] + '-' + today.split('-')[2]}} 平均血糖值</div>
                    <div class="number clear" v-if="date_time == today">
                        <p class="float-left">{{allData.newest_blood_sugar.blood_sugar ? allData.newest_blood_sugar.blood_sugar : "¯.¯"}}</p>
                        <div class="right float-left">
                            <i class="el-icon-top font-weight" :style="{'transform': allData.newest_blood_sugar.trend == 1 || allData.newest_blood_sugar.trend == 0 ? 'rotate(90deg)' : allData.newest_blood_sugar.trend == 5 ? 'rotate(45deg)' : allData.newest_blood_sugar.trend == 20 ? 'rotate(180deg)' : allData.newest_blood_sugar.trend == 10 ? 'rotate(135deg)' : ''}"></i>
                            <span class="pl-5">mmol/L</span>
                        </div>
                    </div>
                    <div class="number clear" v-else>
                        <p class="float-left">{{allData.avg_bloodsugar}}</p>
                        <div class="right">
                            <i class="el-icon-top font-weight" :style="{'transform': allData.newest_blood_sugar.trend == 1 || allData.newest_blood_sugar.trend == 0 ? 'rotate(90deg)' : allData.newest_blood_sugar.trend == 5 ? 'rotate(45deg)' : allData.newest_blood_sugar.trend == 20 ? 'rotate(180deg)' : allData.newest_blood_sugar.trend == 10 ? 'rotate(135deg)' : ''}" style="color: white"></i>
                            <span class="pl-5">mmol/L</span>
                        </div>
                    </div>
                    <div class="average" v-if="date_time == today">
                        平均值：<span>{{allData.avg_bloodsugar ? allData.avg_bloodsugar : '--' }}</span>&nbsp;&nbsp;&nbsp;&nbsp;达标率：{{allData.common_rate}}%
                    </div>
                    <div class="average" v-else>
                        达标率：{{allData.common_rate}}%
                    </div>
                </div>
                <div class="item process" v-if="allData.is_cgm">
                    <Process :tir="allData.tir" :flag="true" :Dark="Dark" />
                </div>
                <div class="item indicator" v-if="!allData.is_cgm">
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>偏高率</span>
                            <span>{{allData.rate.high*100 | tofixed}}%</span>
                        </div>
                    </div>
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>达标率</span>
                            <span>{{allData.rate.normal*100 | tofixed}}%</span>
                        </div>
                    </div>
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>偏低率</span>
                            <span>{{allData.rate.low*100 | tofixed}}%</span>
                        </div>
                    </div>
                </div>
                <div class="item indicator">
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>最高数值</span>
                            <span>{{allData.max_bloodsugar}}mmol/L</span>
                        </div>
                    </div>
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>最低数值</span>
                            <span>{{allData.min_bloodsugar}}mmol/L</span>
                        </div>
                    </div>
                    <div class="indicator-item">
                        <div class="main-con text">
                            <span>波动幅度</span>
                            <span>{{allData.bs_range}}mmol/L</span>
                        </div>
                    </div>
                </div>
                <div class="item indicator-detail">
                    <div class="new-indicator">
                        <div class="row-list">
                            <img src="@/assets/images/icons/u.png" alt="">
                            <p><span class="w1" style="margin-left: 0px"><i>碳</i><i>水</i></span><span>{{allData.liferecord_total.foodrecord_total | empty}}<em>克</em></span></p>
                        </div>
                        <div class="row-list">
                            <img src="@/assets/images/icons/ku.png" alt="">
                            <p>胰岛素 <span>{{allData.liferecord_total.insulin_total | empty}}<em>U</em></span></p>
                        </div>
                        <div class="row-list">
                            <img src="@/assets/images/icons/ci.png" alt="">
                            <p>口服药 <span>{{allData.liferecord_total.medicinerecord_total | empty}} <em>次</em></span></p>
                        </div>
                        <div class="row-list">
                            <img src="@/assets/images/icons/kal.png" alt="">
                            <p><span class="w1" style="margin-left: 0px"><i>运</i><i>动</i></span><span>{{allData.liferecord_total.sportsrecord_total | empty}}<em>千卡</em></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="not_cgm" v-if=" allData.is_cgm == 0 ">

            <div class="tip_data">

                <div class="tip_left">

                <div class="date_list">
                    <h3>达标率</h3>
                    <p>%</p>
                    <h1>{{allData.rate.normal === '' ? '--' : allData.rate.normal * 100}}</h1>
                </div>
                <div class="date_list">
                    <h3>最高值</h3>
                    <p>mmol/L</p>
                    <h1>{{allData.max_bloodsugar === '' ? '--' : allData.max_bloodsugar }}</h1>
                </div>
                <div class="date_list">
                    <h3>最低值</h3>
                    <p>mmol/L</p>
                    <h1>{{allData.min_bloodsugar === '' ? '--' : allData.min_bloodsugar }}</h1>
                </div>

                </div>
                <div class="tip_right">

                <div class="list">
                    <img src="@/assets/images/icons/u.png" alt="">
                    <p>碳<span>水</span></p>
                    <h4>{{allData.liferecord_total.foodrecord_total}}克</h4>
                </div>
                <div class="list">
                    <img src="@/assets/images/icons/ku.png" alt="">
                    <p>胰岛素</p>
                    <h4>{{allData.liferecord_total.insulin_total}}U</h4>
                </div>
                <div class="list">
                    <img src="@/assets/images/icons/ci.png" alt="">
                    <p>口服药</p>
                    <h4>{{allData.liferecord_total.medicinerecord_total}}次</h4>
                </div>
                <div class="list">
                    <img src="@/assets/images/icons/kal.png" alt="">
                    <p>运<span>动</span></p>
                    <h4>{{allData.liferecord_total.sportsrecord_total}}千卡</h4>
                </div>

                </div>

            </div>
            </div>
            <div class="doctor_order" v-if="allData.analysis != '' ">
                <div class="doctor_info">
                    <img src="@/assets/images/error/notheader.png" alt="">
                    <p>每日解读</p>
                </div>
                <div class="order">
                    <p>{{allData.analysis}}</p>
                </div>

            </div>
            <div class="chart-line" v-if="allData.is_cgm == 1 ">
                <ChartsLine :value="allData.data" :target_low="allData.target_low" :target_high="allData.target_high" :specialArr="allData.life_record_coord" v-if="loading" />
            </div>
            <div class="chart-line" v-if="allData.is_cgm == 0 ">
                <FinGetBlood :value="allData.data" :target_low="allData.target_high" :target_high="allData.target_low" />
            </div>
            <div class="scheme-module" v-if="lifeRecord && lifeRecord.length">
                <div class="data-card" v-for="(item, index) in lifeRecord" :key="index">
                    <template v-if="item.kind == 1 || item.kind == 4">
                        <div class="breakfast">
                            <div class="title">
                                <div class="breakfast-value" v-if="item.kind == 1">
                                    <p>{{item.record.food_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.food_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.analysis.title ? item.analysis.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 2">
                                    <p>{{item.record.medicine_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.medicine_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 4">
                                    <p>{{item.record.ketone_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.ketone_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else>
                                    <p>{{item.record.sports_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.sports_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                            </div>
                            <template v-if="item.record.detail">
                                <div class="food" :style="{'overflow-y' : item.record.detail.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.record.detail" :key="idx">
                                        <p>
                                            <img v-if="item.kind == 1" src="@/assets/images/icons/u.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 1" src="@/assets/images/icons/ci.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 2" src="@/assets/images/icons/ku.png" alt="">
                                            <img v-if="item.kind == 3" src="@/assets/images/icons/kal.png" alt="">
                                            <img v-if="item.kind == 4" src="@/assets/images/icons/urine.png" alt="">
                                            {{items.title}}
                                            <span>{{items.num}}{{items.unit}}</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.medicine">
                                <div class="food" :style="{'overflow-y' : item.medicine.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.medicine" :key="idx">
                                        <p>
                                            <img src="@/assets/images/icons/ku.png" v-if="items.type == 2" alt="">
                                            <img src="@/assets/images/icons/ci.png" v-if="items.type == 1" alt="">
                                            {{titleSplit(items.title)}}
                                        </p>
                                        <p>{{items.period}}</p>
                                    </div>
                                </div>
                            </template>
                            <div class="food-pics">
                                <div class="pics" v-for="(items, idx) in item.record.pics" @click="pushList(item.record.pics,idx)" :key="idx">
                                    <img :src="items" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="fluctuation">
                            <template v-if="allData.is_cgm == 1 && item.analysis != ''">
                                <div class="value">
                                    <div class="up-suger" :class="{'isHigh' : item.analysis.up_range_status == 'high'}">
                                        <p>升糖幅度</p>
                                        <h4>{{item.analysis.up_range}}</h4>
                                        <p>mmol/L</p>
                                    </div>
                                    <div class="fluctuation-value" :class="{'isHigh' : item.analysis.wave_range_status == 'high'}">
                                        <p>波动值</p>
                                        <h4>{{item.analysis.wave_range}}</h4>
                                        <p>mmol/L</p>
                                    </div>
                                </div>
                                <div class="intake">
                                    <div class="water">
                                        <div class="title">
                                            <img src="@/assets/images/icons/u.png" alt="">
                                            <p>{{ isIpad ? '碳水' : '碳水摄入' }}</p>
                                        </div>
                                        <p>
                                            {{item.record.carbohydrate}}
                                            <span v-if="item.record.carbohydrate != '--'">克</span>
                                        </p>
                                    </div>
                                    <div class="insulin">
                                        <div class="title">
                                            <img src="@/assets/images/icons/ku.png" alt="">
                                            <p>胰岛素</p>
                                        </div>
                                        <p>
                                            {{item.analysis.insulin_num}}
                                            <span v-if="item.analysis.insulin_num != '--'">U</span>
                                        </p>
                                    </div>
                                    <div class="oral-liquid">
                                        <div class="title">
                                            <img src="@/assets/images/icons/ci.png" alt="">
                                            <p>口服药</p>
                                        </div>
                                        <p>
                                            {{item.analysis.oral_medicine_num}}
                                            <span v-if="item.analysis.oral_medicine_num != '--'">次</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="chart-main">
                                    <ChartsLine :value="item.analysis.list" :target_low="item.user_info.target_low" :target_high="item.user_info.target_high" :type="false" :specialArr="item.analysis.life_record_coord" />
                                </div>
                            </template>
                            <template v-else>
                                <div class="center-box">
                                    <div class="notDate">
                                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                        <h3>暂无数据</h3>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="value-list">
                            <template v-if="allData.is_cgm == 1 && item.analysis != ''">
                                <div class="standard">
                                <div class="before-meal">
                                    <p>餐前血糖值</p>
                                    <em>{{item.analysis.before_time}}</em>
                                    <h4 :class="[ item.analysis.before_bs_status == 'high' ? 'isHigh' : ( item.analysis.before_bs_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.before_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                <div class="after-meal">
                                    <p>餐后最高值</p>
                                    <em>{{item.analysis.highest_time}}</em>
                                    <h4 :class="[ item.analysis.highest_bs_status == 'high' ? 'isHigh' : ( item.analysis.highest_bs_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.highest_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                <div class="meal-two">
                                    <p>餐二血糖值</p>
                                    <em>{{item.analysis.after_time}}</em>
                                    <h4 :class="[item.analysis.after_bs_status == 'high' ? 'isHigh' : ( item.analysis.after_bs_status == 'low' ? 'isLow' : 'normal' ) ]">{{item.analysis.after_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                </div>
                                <div class="recover" ref="offsetWidth">
                                <div class="before-meal">
                                    <p ref="centerLeft">恢复到餐前值</p>
                                    <span>{{item.analysis.before_bs}}<em>mmol/L</em></span>
                                    <h4 :class="[ item.analysis.resume_before_status == 'high' ? 'isHigh' : ( item.analysis.resume_before_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.resume_before}}<span v-if="item.analysis.resume_before != '--'">分</span></h4>
                                </div>
                                <div class="after-meal">
                                    <p>{{ isIpad ? '恢复餐二' : '恢复餐二标准值' }}</p>
                                    <span>7.8<em>mmol/L</em></span>
                                    <h4 :class="[ item.analysis.resume_standard_status == 'high' ? 'isHigh' : ( item.analysis.resume_standard_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.resume_standard}}<span v-if="item.analysis.resume_standard != '--'">分</span></h4>
                                </div>
                                <div class="meal-two">
                                    <p>食物吸收时间</p>
                                    <span>1.7<em>mmol/L</em></span>
                                    <h4>{{item.analysis.absorb_time}}<span v-if="item.analysis.absorb_time != '--'">分</span></h4>
                                </div>
                                </div>
                                <div class="remark">
                                    <div class="remark-text">
                                        <p>备注：<span :title="item.record.note">{{item.record.note.substring(0,50)}}{{item.record.note.length > 50 ? '...' : '' }}</span>
                                            <i class="el-icon-edit-outline" @click="item.editRemark = !item.editRemark"></i>
                                        </p>
                                        <div class="add-remark" v-if="item.editRemark">
                                            <div class="textarea-box">
                                                <textarea placeholder="请输入备注..." v-model="item.remarkValue"></textarea>
                                            </div>
                                            <div class="button">
                                                <el-button @click="item.editRemark = !item.editRemark">取消</el-button>
                                                <el-button class="submit" @click="submitRemark(item.record.id,index,item.remarkValue)">确定</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="center-box">
                                    <div class="notDate">
                                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                        <h3>暂无数据</h3>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="longPass">
                            <div class="title">
                                <div class="breakfast-value" v-if="item.kind == 1">
                                    <p>{{item.record.food_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.food_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 2">
                                    <p>{{item.record.medicine_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.medicine_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 3">
                                    <p>{{item.record.sports_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.sports_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 4">
                                    <p>{{item.record.ketone_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.ketone_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 5">
                                    <p>{{item.record.pure_finger_blood_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.pure_finger_blood_time_str.split(' ')[1]}}</span></p>
                                </div>
                            </div>
                            <template v-if="item.record.detail && item.kind < 4">
                                <div class="food" :style="{'overflow-y' : item.record.detail.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.record.detail" :key="idx">
                                        <p>
                                            <img v-if="item.kind == 1" src="@/assets/images/icons/u.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 1" src="@/assets/images/icons/ci.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 2" src="@/assets/images/icons/ku.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 3" src="@/assets/images/Patient/Details/GLP.png" alt="">
                                            <img v-if="item.kind == 3" src="@/assets/images/icons/kal.png" alt="">
                                            {{items.title}}
                                            <span>{{items.num}} {{items.unit}}</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.kind > 3">
                                <div class="food">
                                    <div class="food-list">
                                        <p>
                                            <img v-if="item.kind == 4" src="@/assets/images/icons/urine.png" alt="">
                                            <img v-if="item.kind == 5" src="@/assets/images/icons/blood.png" alt="">
                                            {{ item.record.title }} 
                                            <span v-if="item.kind == 5">&nbsp;{{item.record.brand_title}} {{Number(item.record.fb_val).toFixed(1)}} mmol/L</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.medicine">
                                <div class="food" :style="{'overflow-y' : item.medicine.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.medicine" :key="idx">
                                        <p>
                                            <img src="@/assets/images/icons/ku.png" v-if="items.type == 2" alt="">
                                            <img src="@/assets/images/icons/ci.png" v-if="items.type == 1" alt="">
                                            {{titleSplit(items.title)}}
                                        </p>
                                        <p>{{items.period}}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
            <div class="empty" style="padding:100px 0;" v-show="!lifeRecord||!lifeRecord.length">
                <div class="center">
                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                    <p>暂无生活记录数据</p>
                </div>
            </div>
        </div>

        <el-drawer class="shadow carousel pion-fixed" :size="'70%'" title="我是标题" :modal-append-to-body="false" :append-to-body="false" :visible.sync="showPicList" :with-header="false" v-if="showPicList">
            <el-carousel :autoplay="false" indicator-position="outside" :arrow="isIpad ? 'always' : 'hover' " :initial-index="picIndex">
                <el-carousel-item v-for="item in carouselList" :key="item">
                    <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </el-drawer>
    </div>
</template>

<script>
    import { time } from '_js/Cache'
    import { Process } from '../Model/index';
    import { ChartsLine, FinGetBlood } from '_c/Charts/index';
    import { addNote } from 'quest';
    export default {
        props:{
            allData: Object,
            lifeRecord: Object,
            Dark: Boolean
        },

        watch:{
            today(val){
                this.$emit('dateChange',val);
            },
            allData:{
                handler(val){
                    if(val != null && val != 'null'){
                        this.hasAllData = true;
                    }
                },
                deep: true
            },
            lifeRecord:{
                handler(val){
                    if(val != null && val != 'null'){
                        this.hasLifeData = true;
                        this.addStr(true);
                    }else{
                        this.loading = false
                    }
                },
                deep: true
            }
        },
        components:{
            Process,
            ChartsLine,
            FinGetBlood
        },

        data(){
            return{
                today: '',
                selectDate: '',
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
                },
                empty: "",
                loading: false,
                hasAllData: false,
                hasLifeData: false,
                date_time: new Date().getFullYear() + '-' + ( parseInt(new Date().getMonth()) + 1 < 10 ? '0' + ( parseInt(new Date().getMonth() ) + 1 ) : parseInt(new Date().getMonth()) + 1 )  + '-' + (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate() ),
                carouselList: [],
                picIndex: 0,
                showPicList: false,
                isIpad: false,
            }
        },
        filters: {
            empty(value) {
                if (!value && value != 0) {
                    return "--";
                } else {
                    return value;
                }
            },
            tofixed(num) {
                return Math.floor(num);
            }
        },
        methods:{
            changeDateByIcon(step) {
                let self = this,
                    date = this.today,
                    today = time(new Date(),'YYYY-MM-DD');
                //加一天
                function addDate(time) {
                    //加一天
                    var timestamp = Date.parse(new Date(time));
                    timestamp = timestamp / 1000;
                    today = new Date(today) / 1000;
                    if( today > timestamp ){
                        self.loading = false;
                        timestamp += 86400; //加一天
                        var newTime = new Date(timestamp * 1000).format("yyyy-MM-dd");
                        return newTime;
                    }else{
                        return time
                    }
                }

                //减一天
                function delDate(time) {
                    self.loading = false;
                    var timestamp = Date.parse(new Date(time));
                    timestamp = timestamp / 1000;
                    timestamp -= 86400; //加一天
                    var newTime = new Date(timestamp * 1000).format("yyyy-MM-dd");

                    return newTime;
                }

                //日期格式
                Date.prototype.format = function(format) {
                    var date = {
                        "M+": this.getMonth() + 1,
                        "d+": this.getDate(),
                        "h+": this.getHours(),
                        "m+": this.getMinutes(),
                        "s+": this.getSeconds(),
                        "q+": Math.floor((this.getMonth() + 3) / 3),
                        "S+": this.getMilliseconds()
                    };
                    if (/(y+)/i.test(format)) {
                        format = format.replace(
                            RegExp.$1,
                            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                        );
                    }
                    for (var k in date) {
                        if (new RegExp("(" + k + ")").test(format)) {
                            format = format.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? date[k]
                                : ("00" + date[k]).substr(("" + date[k]).length)
                            );
                        }
                    }
                    return format;
                };
                if (step > 0) {
                    this.today = addDate(date);
                } else {
                    this.today = delDate(date);
                }
            },

            selectDay(val){
                if(val != 'null' && val != null){
                    this.today = time(val,'YYYY-MM-DD');
                }else{
                    this.today =  time(new Date(),'YYYY-MM-DD')
                }
            },

            addStr(boole){
                let timer = setInterval( () =>{
                    if(this.hasAllData && boole){
                        this.loading = true;
                    }
                    clearInterval(timer);
                },100)
            },

            submitRemark(id,index,note){
                const data = {
                    id,
                    type: 1,
                    note
                };
                if(!note){
                    this.$message.warning("请输入备注");
                }else{
                    addNote(data).then(res => {
                        if (res.data.code == 100000) {
                            this.$message.success('提交成功！');
                            this.$emit('addNote',{index,note})
                        } else {
                            this.$message.error('提交失败！请重试');
                        }
                    })
                }

            },
            pushList(list,idx){
                this.carouselList = list;
                this.picIndex = idx;
                this.showPicList = true;
            },
            titleSplit(title){
                return title.split(' ')[0].length > 3 ? title.split(' ')[0].substring(0,3) + '... ' + (title.split(' ')[1].length > 3 ? title.split(' ')[1].substring(0,4) : title.split(' ')[1] ) : title;
            }
        },
        created(){
            this.today = time(new Date(),'YYYY-MM-DD');
        },
        mounted(){
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        }
    }
</script>

<style scoped lang="less">
    .rotate45 {
        transform: rotate(45deg);
    }

    .rotate90 {
        transform: rotate(90deg);
    }

    .rotate135 {
        transform: rotate(135deg);
    }

    .rotate180 {
        transform: rotate(180deg);
    }

    .size {
        width: 100%;
        height: 100%;
    }

    .shadow.carousel{
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 999999;
        /deep/ .rtl{
            background: none;
            right: 15%;
            box-shadow: none;

            .el-drawer__body{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                i{
                    background-color: #c1c1c1;
                    padding: 10px;
                    border-radius: 50px;
                    color: #0c0c0c;
                }
                .el-carousel{
                    width: 100%;
                    height: 100%;
                    .el-carousel__container{
                        height: 100% !important;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                }
            }
        }
    }

    .everyDay{
        .date{
            height: 80px;
            background: #f7f7f7;
            transition: .3s;

            .info{
                display: flex;
                align-items: center;
                left: 15px;
                width: 300px;

                &>img{
                    width: 50px;
                    height: 50px;
                }

                .center-box{
                    width: unset !important;
                    height: unset !important;
                    display: block !important;
                    min-height: unset !important;
                    padding-left: 15px;
                }

                .name{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                }

                .number{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                }

            }

            .select{
                font-size: 20px;
                font-family: PingFangSC-Medium,PingFang SC;
                color: #666;

                i{
                    cursor: pointer;
                }
            }

            .dateSelect {
                right: 20px;
                width: 280px;
                height: 32px;
            }

        }

        .dayDate{

            .isCgm{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                height: 150px;
                padding-left: 50px;
                margin-top: 20px;

                .item {
                    flex: 1;
                    height: 100%;
                }
                .xuetangzhi {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        font-size: 22px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #1f2e4d;
                        line-height: 22px;
                    }

                    .number {
                        font-size: 56px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #1f2e4d;
                        line-height: 28px;
                        align-items: baseline;
                        min-width: 0%;

                        p{
                            margin-top: 20px;
                        }

                        div{

                            i{
                                font-size: 45px;
                            }

                            span{
                                display: block;
                                font-size: 14px;
                                margin-top: -10px;
                            }
                        }

                    }

                    .average{
                        color: #515a6e;
                    }

                    .status {
                        width: 83px;
                        height: 33px;
                        background: #44d7b6;
                        border-radius: 8px;
                        font-size: 17px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 33px;
                        text-align: center;
                    }

                    .status.height {
                        background: #ffe452;
                    }

                    .status.low {
                        background: #ff6e6e;
                    }
                }

                .process {
                    margin-left: 20px;
                }

                .indicator {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: space-between;

                    .indicator-item{
                        width: 100%;
                        span{
                            em{
                                font-style: normal;
                            }
                        }
                    }

                    .main-con {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 200;
                        color: #242424;
                        line-height: 30px;
                        display: flex;

                        span:nth-of-type(2) {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }

                    .main-con.text{
                        width: 100%;
                        flex-shrink: 0;
                    }

                    .info {
                        font-size: 11px;
                        font-family: PingFang-SC-Regular, PingFang-SC;
                        font-weight: 400;
                        color: #9f9f9f;
                        line-height: 15px;
                    }
                }
                .indicator-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .indicator-item {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                    }

                    .new-indicator{
                        display:flex;
                        flex-wrap: wrap;
                        height: 100%;
                        align-items: center;

                        .row-list{
                            width: 100%;
                            display: flex;
                            align-items: center;

                            img{
                                width: 25px;
                                height: 25px;
                            }

                            p{
                                width: 150px;
                                text-align: left;
                                margin-left: 15px;
                                display: flex;
                                font-size:16px;
                                font-weight: 500;

                                span{
                                    margin-left: 25px;

                                    i{
                                        font-style: normal;
                                    }

                                    em{
                                        font-style: normal;
                                        font-size:12px;
                                    }
                                }
                            }
                        }
                    }

                    .img {
                        width: 18px;
                        height: 18px;

                        img {
                            .size;
                        }
                    }

                    .main-con {
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #aaaaaa;
                        line-height: 23px;
                        margin-left: 10px;
                        margin-right: 50px;
                        position: relative;
                        top: -4px;
                    }

                    .info {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #242424;
                        line-height: 18px;
                    }
                }
            }

            .doctor_order{
                display: flex;
                align-items: center;
                margin-top: 50px;

                .doctor_info{
                    width: 88px;
                    text-align: center;

                    img{
                        width: 37px;
                        height: 37px;
                    }

                    p{
                        font-size: 13px;
                        color: #9A9A9A;
                    }
                }

                .order{
                    width: ~'calc(100% - 100px)';
                    margin-left: auto;
                    margin-right: 15px;
                    padding: 20px;
                    border: 1px solid #EFEFEF;
                }
            }

            .chart-line {
                width: 100%;
                height: 230px;
                margin-top: 20px;

                .ChartsLine,
                .charts {
                    width: 100%;
                    height: 230px;
                    & > *{
                        width: 100%;
                        height: 100%;
                    }

                    div {
                        height: 100%;
                    }
                }
            }

            .not_cgm{

                .tip_data{
                    display: flex;
                    width: 100%;
                    padding: 0px 80px;
                    justify-content: space-between;
                    margin-top: 55px;

                    .tip_left{
                        margin-top: 10px;
                        width: ~'calc(100% - 300px)';
                        display: flex;
                        justify-content: space-between;

                        .date_list{
                            text-align: center;

                            h3{
                                font-size: 22px;
                                color: #1F2E4D;
                            }

                            p{
                                font-size: 14px;
                                color: #9F9F9F;
                            }

                            h1{
                                font-size: 55px;
                                color: #1f2e4d;
                                margin-top: -15px;
                            }
                        }
                    }
                    .tip_right{

                        .list{
                            display: flex;
                            width: 180px;
                            align-items: center;

                        img{
                            width: 22px;
                            height: 22px;
                        }

                        p{
                            font-size: 20px;
                            color: #AAAAAA;
                            margin-left: 10px;
                            min-width: 60px;
                            display: flex;
                            justify-content: space-between;
                        }

                        h4{
                            font-size: 17px;
                            color:#242424;
                            margin-left: 15px;
                        }
                    }

                    .list:nth-child(n+2){
                        margin-top: 14px;
                    }
                }
                }
            }
        }

        .scheme-module{
            width: 100%;

            .scheme-title {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .data-card {
                width: 100%;
                background: #ffffff;
                border-radius: 1px;
                border: 1px solid #e6e9f0;
                margin-top: 20px;
                padding: 10px 0;
                display: flex;

                &>div{
                    min-width: 33.3333333333%;
                }

                .breakfast{
                    padding: 15px 20px;
                    position: relative;

                    .title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 10px;
                        margin-bottom: 18px;

                        .breakfast-value{
                            font-size: 19px;

                            span{
                                margin-left: 5px;
                            }

                            h4{
                                font-size: 20px;
                                margin-top: 5px;
                            }
                        }
                    }
                    .food{
                        max-height: 200px;

                        .food-list{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 10px;
                            background: #f9f9f9;
                            height: 40px;
                            border-radius: 4px;
                            padding: 5px 10px;

                            p{
                                display: flex;
                                // align-items: center;
                                font-size: 15px;

                                img{
                                    width: 25px;
                                    height: 25px;
                                    margin-right: 10px;
                                }
                                span{
                                    display: block;
                                    width: 80px;
                                    text-align: right;
                                }
                            }
                            p:nth-of-type(2){
                                color: #aaa;
                            }
                        }
                    }
                    .food-pics{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        .pics{
                            width: 75px;
                            height: 75px;
                            overflow: hidden;
                            margin: 10px 5px;

                            img{
                                width: 100%;
                                height: 100%;
                                display: block;
                                border-radius: 5px;
                            }
                        }
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        top:0px;
                        right: 0px;
                        width:1px;
                        height:100%;
                        background: #e8e8e8;
                    }
                }

                .fluctuation{
                    padding: 15px 20px;
                    position: relative;

                    .value{
                        display:flex;
                        .up-suger,
                        .fluctuation-value{

                            width: 80px;
                            height: 80px;
                            border-radius: 8px;
                            text-align: center;
                            padding: 5px 0px;
                            box-sizing: border-box;
                            background: #26c3a8;
                            color: white;

                            p{
                                font-size: 12px;
                            }

                            h4{
                                font-size:28px;
                            }

                            p:nth-of-type(2){
                                font-size: 15px;
                                transform: scale(0.8);
                                margin-top: -8px;
                            }
                        }

                        .fluctuation-value{
                            margin-left: 15px;
                        }

                        .text{
                            margin-left: auto;
                        }

                        .isHigh{
                            background: var(--bloodSugarHigh);
                        }
                        .isLow{
                            background: var(--bloodSugarLow);
                        }
                        .normal{
                            background: var(--defalutColor);
                        }
                    }

                    .intake{
                        display: flex;
                        justify-content: space-between;
                        margin: 20px 0px;
                        margin-bottom: 10px;
                        font-size: 12px;
                        padding: 10px auto;

                        .title{
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 26px;
                                height: 26px;
                            }

                            p{
                                margin-left: 5px;
                                font-size: 14px;
                            }
                        }
                        &>div{
                            flex: 1;
                            position: relative;
                            text-align: center;
                            padding: 10px 0px;

                            >p{
                                font-size: 38px;
                                color: #000;
                                font-weight: bold;

                                span{
                                    font-weight: normal;
                                }
                            }
                        }
                        .water,
                        .insulin{

                            &::before{
                                content: '';
                                position:absolute;
                                width:1px;
                                height: 100%;
                                right:0px;
                                top:0px;
                                background:#f7f7f7;
                            }
                        }
                        span{
                            font-size: 12px;
                            transform: scale(0.5);
                            color:#c2c2c2;
                            font-weight: unset;
                        }
                    }

                    .chart-main{
                        height: 158px;

                        .ChartsLine{
                            height: 158px;
                        }
                    }

                    &::after{
                        content: '';
                        position: absolute;
                        top:0px;
                        right: 0px;
                        width:1px;
                        height:100%;
                        background: #e8e8e8;
                    }
                }
                .value-list{

                    .standard,
                    .recover,
                    .remark{
                        display: flex;
                        padding: 25px 20px;

                        &>div{
                            flex: 1;
                            text-align: center;
                        }

                        .remark-text{
                            display: flex;
                            text-align: left;
                            transition: .3s;
                            margin-left: 20px;

                            i{
                                font-size: 22px;
                                color:#8d92a3;
                                cursor: pointer;
                                transition: .3s;
                                margin-top: -5px;

                                &:hover{
                                    color: #13be9b;
                                    transition: .3s;
                                }
                            }
                        }

                        p{
                            display: inline-block;
                            font-size: 13px;
                            color: #000;
                        }

                        em{
                            display:block;
                            font-size: 13px;
                            color: #aeaeaes;
                            font-style: normal;
                            color: #aeaeae;
                        }

                        h4{
                            font-size: 32px;
                            font-weight: bolder;
                        }

                        span{
                            font-size: 15px;
                            color: #aeaeae;
                        }
                    }
                    .standard{

                        h4{
                            color: #26C3A8;
                        }

                        .isHigh{
                            color: var(--bloodSugarHigh) !important;
                        }

                        .isLow{
                            color: var(--bloodSugarLow) !important;
                        }

                        .normal{
                            color: var(--defalutColor) !important;
                        }
                    }

                    .recover{

                        .before-meal,
                        .after-meal,
                        .meal-two{

                            h4{
                                color: #242424;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span{
                                    display: block;
                                    margin-top: 15px;
                                    margin-left: 5px;
                                    font-weight: normal;
                                    font-size: 19px;
                                }
                            }

                            &>span{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 13px;
                                color: #484848;

                                em{
                                    color: #484848;
                                }
                            }
                        }

                        .isHigh{
                            color: var(--bloodSugarHigh) !important;
                        }

                        .isLow{
                            color: var(--bloodSugarLow) !important;
                        }

                        .normal{
                            color: var(--defalutColor) !important;
                        }
                    }
                    
                    .remark{
                        position: relative;

                        .remark-text{

                            p{
                                font-size: 15px;
                                color: #aeaeae;

                                span{
                                    color: #232323;
                                }
                            }
                        }

                        .add-remark{
                            position: absolute;
                            top: -220px;
                            left: -315px;
                            background: white;
                            box-shadow: 0px 0px 5px #e3e3e3;
                            width:705px;
                            height: 235px;
                            padding: 20px;

                            .textarea-box{
                                width: 660px;
                                height: 145px;
                                border: 1.5px solid #e3e3e3;
                                padding: 10px 15px;

                                textarea{
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    resize: none;
                                    outline: none;
                                }
                            }

                            .button{
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 15px;

                                button{
                                    width:100px;
                                    height:35px;
                                    border-radius: 5px;
                                }

                                .submit{
                                    background: #13be9b;
                                    color: white;
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }

                .center-box{
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        max-width: 106px;
                    }

                    h3{
                        text-align: center;
                        font-size: 18px;
                    }
                }

                .notInfo{
                    width: 235px;
                }

                .item {
                    flex: 1;
                    padding: 0 20px;
                    border-right: 1px solid #ddd;
                }

                .card-accounting {
                    border-right: 0px solid #ddd;
                }

                .header {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #242424;

                    .unit {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }

                .content {
                    width: 100%;
                    margin-top: 10px;

                    .food-card-box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        height: 170px;

                        .item-box {
                            width: 28%;
                            height: 45px;
                            text-align: center;
                            background: #13be9b;
                            margin-right: 14px;
                            padding: 5px;
                            margin-bottom: 10px;
                            border-radius: 4px;
                            color: #fff;
                            position: relative;

                            .tip {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                z-index: 2;
                            }

                            p {
                                font-size: 12px;
                                max-width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin: 0 auto;
                            }
                        }
                    }

                    .food-plus-box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        height: 170px;
                        overflow-y: auto;

                        .item-box {
                            width: 28%;
                            height: 45px;
                            text-align: center;
                            background: #fff;
                            border: 1px solid #ccc;
                            margin-right: 14px;
                            padding: 5px;
                            margin-bottom: 10px;
                            border-radius: 4px;
                            position: relative;

                            .tip {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                z-index: 2;
                            }

                            .icon {
                                display: block;
                                font-size: 15px;
                                font-weight: 600;
                                cursor: pointer;
                                margin: 10px auto;
                            }

                            p {
                                font-size: 12px;
                                margin: 0 auto;
                                max-width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                img {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }

                    .food-plus-box::-webkit-scrollbar { 
                        width: 0 !important 
                    }

                    .food-plus-box{ 
                        -ms-overflow-style: none; 
                        overflow: -moz-scrollbars-none; 
                    }

                    .beizhu {
                        display: flex;
                        font-size: 12px;
                    }

                    .img-card-box {
                        width: 330px;
                        overflow-x: auto;
                        display: flex;

                        .img-item {
                            width: 35px;
                            height: 35px;
                            margin-right: 10px;

                            img {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                    .class-box {
                        display: flex;
                        justify-content: space-between;

                        .class-box-item {
                            text-align: center;

                            .title {
                                font-size: 12px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #242424;
                                line-height: 9px;
                            }

                            .time {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9f9f9f;
                                line-height: 11px;
                                margin: 10px 0 3px;
                            }

                            .number {
                                font-size: 35px;
                                font-family: PingFang-SC-Heavy, PingFang-SC;
                                font-weight: 800;
                                color: #26c3a8;
                                line-height: 35px;
                            }

                            .number.high {
                                color: #ffe452;
                            }

                            .number.low {
                                color: #ff6e6e;
                            }

                            .unit {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9f9f9f;
                                line-height: 11px;
                                margin: 5px 0 0;
                            }
                        }
                    }

                    .chart {
                        width: 100%;
                        height: 120px;

                        .charts {
                            width: 100%;
                            height: 100%;

                            div {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .longPass{
                    width: 100%;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 19px;

                    .breakfast-value{

                        span{
                            margin-left: 10px;
                        }
                    }

                    img{
                        width: 25px;
                        height: 25px;
                    }

                    .food-list p{
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                        img{
                            margin: 0px 10px;
                        }
                    }
                }
            }
        }

        &.dark{
            .date{
                background: #0c0c0c;
                transition: .3s;
                .select{
                    color: #c1c1c1;
                }
                & /deep/ .dateSelect{
                    .el-input__inner{
                        background-color: unset;
                        border-color: #7d7d7d;
                    }
                }
            }
            .data-card{
                background: unset;
                border-color: #2c2c2e;
                .breakfast-value{
                    color: #c1c1c1;
                }
                .breakfast{
                    .food-list{
                        background: #0c0c0c !important;
                        color: #c1c1c1;
                        p:nth-of-type(2){
                            color: #7f7f7f !important;
                        }
                    }
                    &::after{
                        background: #2c2c2e;
                    }
                }
                .longPass{
                    .food-list{
                        color: #c1c1c1;
                    }
                }
                .fluctuation{
                    &::after{
                        background: #2c2c2e;
                    }
                }
                .up-suger,
                .fluctuation-value{
                    background: #0f947d !important;
                }
                .intake{
                    .title{
                        color: #c1c1c1;
                    }
                    &>div>p{
                        color: #7f7f7f !important;
                        span{
                            color: #7f7f7f;
                        }
                    }
                }
                .insulin,
                .water{
                    &::before{
                        background: #2c2c2e !important;
                    }
                }
                .standard,
                .recover{
                    .before-meal,
                    .after-meal,
                    .meal-two{
                        p{
                            color: #7f7f7f !important;
                        }
                        em{
                            color: #e1e1e1 !important;
                        }
                        h4{
                            color: #0f947d !important;
                        }
                        span{
                            color: #d1d1d1 !important;
                        }
                    }
                }
                .remark-text{
                    p{
                        color: #c1c1c1 !important;
                        i{
                            color: #c1c1c1 !important;
                        }
                    }
                }
            }
            .dayDate{

                .center-box{
                    .name,
                    .number{
                        color: #c1c1c1 !important;
                    }
                }

                .isCgm{
                    .title{
                        color: #c1c1c1 !important;
                    }
                    .float-left{
                        color: #c1c1c1;
                        .right{
                            & > *{
                                color: #c1c1c1 !important;
                            }
                        }
                    }
                    .average{
                        color: #7f7f7f !important;
                    }

                    .indicator{
                        .main-con{
                            color: #c1c1c1 !important;
                        }
                    }
                    .new-indicator{
                        color: #c1c1c1;
                    }
                }

                .date_list{
                    h3,
                    h1{
                        color: white !important;
                    }
                    p{
                        color: #ccc !important;
                    }
                }
                .tip_right{
                    p{
                        color: white !important;
                    }
                    h4{
                        color: white !important;
                    }
                }
            }
            .notDate{
                h3{
                    color: #c1c1c1 !important;
                }
            }
            .not_cgm{
                .date_list{
                    h3,h1{
                        color: #c1c1c1 !important;
                    }
                    p{
                        color: #7f7f7f !important;
                    }
                }
                .list{
                    p{
                        color: #c1c1c1 !important;
                    }
                    h4{
                        color: #c5c5c5 !important;
                    }
                }
            }

            .dateSelect {
                & /deep/ .el-input__inner{
                    height: 32px;

                    &::placeholder{
                        font-size: 12px;
                    }
                }

                & /deep/ .el-input__prefix{
                    top: -5px;
                }

                & /deep/ .el-input__suffix{
                    top: -4px;
                }
                & /deep/ .el-picker-panel{
                    background: #202025;
                    border-color: #0c0c0c;
                    .el-picker-panel__icon-btn,
                    .el-date-picker__header-label{
                        color: #c1c1c1;
                    }
                    .el-date-table th{
                        color: #c1c1c1;
                        border-color: #0c0c0c;
                    }
                    .prev-month span{
                        color: #c1c1c1;
                    }
                    .available{
                        span{
                            color: #fff;
                        }
                        &.current{
                            span{
                                background-color: var(--defalutActiveColor);
                            }
                        }
                    }
                    .normal.disabled div,
                    .next-month.disabled div{
                        background: #0c0c0c;
                        color: #7f7f7f;
                    }
                    .popper__arrow{
                        border-bottom-color: #202025;
                        &::after{
                            border-bottom-color: #202025;
                        }
                    }
                }
            }

            .add-remark{
                background: #0c0c0c !important;
                box-shadow: 0px 0px 5px #0c0c0c !important;
                .textarea-box{
                    border-color: #202025 !important;
                    textarea{
                        background: unset !important;
                        color: #c1c1c1 !important;
                    }
                }
                button{
                    &.el-button--default{
                        background: #0c0c0c;
                        border-color: #202025;
                        &:hover{
                            color: #606266 !important;
                            border-color: #202025 !important;
                        }
                    }
                    &.submit{
                        color: #fff !important;
                        &:hover{
                            color: #fff !important;
                        }
                    }
                }
            }

        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .everyDay{
                .date{
                    .dateSelect{
                        width: 200px !important;
                    }
                }
                .scheme-module{
                    .data-card{
                        .breakfast,
                        .fluctuation{
                            padding: 10px 5px;
                        }

                        .value-list{
                            .standard,
                            .recover{
                                padding: 15px 5px;
                            }
                        }
                        .fluctuation{
                            .intake{
                                &>div{
                                    >p{
                                        font-size: 30px;
                                        span{
                                            margin-left: -5px;
                                        }
                                    }
                                }
                            }
                            .chart-main{
                                .ChartsLine{
                                    margin-top: -35px;
                                }
                            }
                        }
                    }
                }
            }
            

            .indicator{
                display: none !important;
            }
        }
    }

</style>