import axios from 'axios'
import qs from 'qs'
import md5 from 'js-md5'
import { getItem } from '@/assets/js/Cache'
import router from '@/router/index';

class HttpRequest {
    constructor(baseUrl = baseUrl) {
        this.baseUrl = baseUrl
        this.queue = {}
    }
    destroy (url) {
        delete this.queue[url]
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }
    getInsideConfig() {
        const config = {
            // 跨域请求时是否需要使用凭证
            // withCredentials: false,
            /*
                axios 提供了 validateStatus 属性，用于定义对于给    定的HTTP 响应状态码是 resolve 或 reject promise。所以，正常设置的情况下，我们会将状态码为 2 系列或者 304 的请求设为 resolve 状态，其余为 reject 状态。结果就是，我们可以在业务代码里，使用 catch 统一捕获响应错误的请求，从而进行统一处理。
                但是，由于我在代码里面使用了 async-await，而众所周知，async-await 捕获 catch 的方式极为麻烦，所以，在此处，我选择将所有响应都设为 resolve 状态，统一在 then 处理。
            */
            validateStatus: function () {
                // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
                return true
            },
            baseURL: this.baseUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        }
        return config
    }
    showStatus(status){
        let message = ''
        // 这一坨代码可以使用策略模式进行优化
        switch (status) {
            case 400:
                message = '请求错误(400)'
                break
            case 401:
                message = '未授权，请重新登录(401)'
                break
            case 403:
                message = '拒绝访问(403)'
                break
            case 404:
                message = '请求出错(404)'
                break
            case 408:
                message = '请求超时(408)'
                break
            case 500:
                message = '服务器错误(500)'
                break
            case 501:
                message = '服务未实现(501)'
                break
            case 502:
                message = '网络错误(502)'
                break
            case 503:
                message = '服务不可用(503)'
                break
            case 504:
                message = '网络超时(504)'
                break
            case 505:
                message = 'HTTP版本不受支持(505)'
                break
            default:
                message = `连接出错(${status})!`
        }
        return `${message}，请检查网络或联系管理员！`
    }

    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            console.log(config);
            if(!config.isLogin){
                let uuid = 'uuid';
                let timestamp = new Date().getTime();
                let token = getItem('token') || config.data.token;
                let salt = 'yja2019_fcz'
                let commonParam = {
                    uuid,
                    timestamp,
                    token,
                    sign: md5(`${salt}${timestamp}${token}${uuid}`)
                }
                config.data = Object.assign(commonParam, config.data)
            }
            if (config.method == 'post') {
                config.data = qs.stringify(config.data)
            }else if(config.method == 'get' && config.url == '/bloodsugar/export'){
                config.url = config.url + '?' + qs.stringify(config.data);
            }
            this.queue[url] = true;
            return config
        }, (error) => {
            // 错误抛到业务代码
            error.data = {}
            error.data.msg = '服务器异常，请联系管理员！'
            return Promise.resolve(error)
        })
        // 响应拦截
        instance.interceptors.response.use( response => {    
            const status = response.status
            let msg = '';
            if(response.data.code == 200002){
                router.replace({
                    path: '/error'
                });
            }else{
                if (status < 200 || status >= 300) {
                    // 处理http错误，抛到业务代码
                    msg = this.showStatus(status)
                    if (typeof response.data === 'string') {
                        response.data = { msg }
                    } else {
                        response.data.msg = msg
                    }
                }
            }
            return response
        }, (error) => {
            // 错误抛到业务代码
            error.data = {}
            error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
            return Promise.resolve(error)
        })
    }
    request (options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

export default HttpRequest